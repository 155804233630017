import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { Logo, Loader, ReportTypePill, Select, SquareButton } from "../atoms";
import { ApiHelper } from "../../helpers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStarAndCrescent, faTimes } from "@fortawesome/free-solid-svg-icons";
import { default as ReactLoader } from "react-loader-spinner";

export default () => {
  const [contactType, setContactType] = useState(null);
  const [validateContactType, setValidateContactType] = useState(false);
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [message, setMessage] = useState("");
  const [company, setCompany] = useState("");
  const [sector, setSector] = useState("");
  const [sent, setSent] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const contactTypes = [
    {
      label: "Consulta masiva de datos en línea",
      value: "Consulta masiva de datos en línea",
    },
    {
      label: "Homologación de Bases de Datos",
      value: "Homologación de Bases de Datos",
    },
    {
      label: "Asesoramiento técnico",
      value: "Asesoramiento técnico",
    },
    {
      label: "Investigación de mercado",
      value: "Investigación de mercado",
    },
    {
      label: "Publicidad",
      value: "Publicidad",
    },
    {
      label: "Prensa, Comunicación y Lanzamientos",
      value: "Prensa, Comunicación y Lanzamientos",
    },
    {
      label: "Infogrid Grupal",
      value: "Infogrid Grupal",
    },
    {
      label: "Otros",
      value: "Otros",
    },
  ];

  const submitForm = async (e) => {
    e.preventDefault();

    if (!contactType) {
      setValidateContactType(true);
      return;
    }

    const data = {
      email,
      subject: `${contactType.value} ${firstName} ${lastName}`,
      message: `
            ${contactType.value}: ${firstName} ${lastName}
            Email: ${email}
            Telefono: ${phone}
            Razon Social: ${company}
            Sector: ${sector}
            Mensaje: ${message}`,
    };

    try {
      setIsFetching(true);
      await ApiHelper.post("admin", "/mail/companies", data);
      setSent(true);
      setIsFetching(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <section id={"enterprise_contact"} className={"p-5 background_gray"}>
      <div className="container d-flex flex-column align-items-center justify-content-center py-5">
        <h2 className="font-weight-bold text-center">
          Contacto <Logo /> Empresas
        </h2>
        <form id="contact-form" onSubmit={submitForm}>
          {sent ? (
            <div>
              <div className="row">
                <div className="col-12 gray_background p-5 text-center">
                  <h4 className="font-weight-normal mb-0">
                    <strong>Mensaje enviado</strong>!
                  </h4>
                </div>
              </div>
            </div>
          ) : (
            <div className="row mt-3">
              <div className="col-12 col-md-6 mb-4">
                <label className={"font-weight-bold"}>Nombre</label>
                <input
                  required
                  name={"firstName"}
                  defaultValue={firstName}
                  onChange={(event) => setFirstName(event.target.value)}
                  className={"custom_input"}
                  placeholder={"Nombre"}
                  type={"text"}
                />
              </div>
              <div className="col-12 col-md-6 sm-12 mb-4">
                <label className={"font-weight-bold"}>Apellido</label>
                <input
                  required
                  name={"lastName"}
                  defaultValue={lastName}
                  onChange={(event) => setLastName(event.target.value)}
                  className={"custom_input "}
                  placeholder={"Apellido"}
                  type={"text"}
                />
              </div>
              <div className="col-12 col-md-6 sm-12 mb-4">
                <label className={"font-weight-bold"}>E-Mail</label>
                <input
                  required
                  name={"email"}
                  defaultValue={email}
                  onChange={(event) => setEmail(event.target.value)}
                  className={"custom_input "}
                  placeholder={"Email"}
                  type={"email"}
                />
              </div>
              <div className="col-12 col-md-6 sm-12 mb-4">
                <label className={"font-weight-bold"}>Razón Social</label>
                <input
                  name={"phone"}
                  defaultValue={company}
                  onChange={(event) => setCompany(event.target.value)}
                  className={"custom_input "}
                  placeholder={"Empresa"}
                  type={"text"}
                />
              </div>
              <div className="col-12 col-md-6 sm-12 mb-4">
                <label className={"font-weight-bold"}>Rubro</label>
                <input
                  name={"phone"}
                  defaultValue={sector}
                  onChange={(event) => setSector(event.target.value)}
                  className={"custom_input "}
                  placeholder={"Sector"}
                  type={"text"}
                />
              </div>
              <div className="col-12 col-md-6 sm-12 mb-4">
                <label className={"font-weight-bold"}>Tipo de Consulta</label>
                <Select
                  required
                  className={"w-100"}
                  placeholder={"Seleccioná"}
                  onSelect={(type) => setContactType(type)}
                  selectedOption={contactType}
                  options={contactTypes}
                />
                {contactType === null && validateContactType && (
                  <div className="d-flex mt-3 ml-2  align-items-center">
                    <p style={{ color: "red" }}>
                      Seleccione un tipo de consulta.
                    </p>
                  </div>
                )}{" "}
              </div>
              <div className="col-12 sm-12 mb-4">
                <label className={"font-weight-bold"}>Mensaje</label>
                <textarea
                  required
                  defaultValue={message}
                  onChange={(event) => setMessage(event.target.value)}
                  placeholder={"Escriba su mensaje"}
                  className={"custom_input text_area w-100 p-2 px-3"}
                />
              </div>
              <div className="col-12  text-center mt-3 d-flex flex-column align-items-center align-items-md-end">
                <a className={"font-weight-bold mr-3"}>
                  infoauto@infoauto.com.ar
                </a>
                <div className="w-25 mt-2">
                  <SquareButton
                    size={"sm"}
                    loading={isFetching}
                    onClick={submitForm}
                    text={"ENVIAR"}
                  />
                </div>
              </div>
            </div>
          )}
        </form>
      </div>
    </section>
  );
};
